body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rsw-dd {
  display:none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --font-main: Zilla Slab, serif;
  --font-headers: Telegrafico, Zilla Slab, sans-serif;
  --font-numbers: Arial, Helvetica, sans-serif;
  --color-bg: #FFF6E5;
  --color-main: #000000;
  --color-italic: #6A2F3F;
  --color-primary: #AB2D4E;
  --color-primary-light: #C43558;
  --color-primary-dark: #81223B;
  --color-secondary: #00A0B0;
  --color-secondary-light: #00B8C9;
  --color-secondary-dark: #096A74;
  --border-radius-small: 12px;
  --border-radius-large: 20px;
  --card-margin: 24px;
  --navbar-thickness: 40px;
}

.table {
  margin-top: -8px;
  margin-bottom: 0px;
}

.table a {
  color: #0c0c0c;
}

body {
  background-color: var(--color-bg);
}

.btn-danger {
  background-color: var(--color-primary)!important;
  border-color: var(--color-primary)!important;
}

.btn-primary {
  background-color: var(--color-secondary-dark)!important;
  border-color: var(--color-secondary-dark)!important;
}

.btn:hover {
  opacity: 0.9;
}

.header{
  background-color: var(--color-primary-dark)!important;
  padding-left: 1vw;
  padding-right: 1vw;
  border-radius: 0 0 6px 6px;
}

.badge {
  background-color: var(--color-primary);
  border-radius: 6px;
  padding: 6px;
  font-weight: normal;
}

.badge a:hover {
  color: rgba(255, 255, 255, 0.774);
}

.user {
  padding-right: 8px;
}

.logout {
  padding-left: 8px;
}

.hstack.gap-2 {
  padding-top: 6px;
}

.form-label {
  padding-top: 8px;
}

.card-header{
  background-color: var(--color-secondary-dark);
  color: white;
  font-weight: bold;
}

.card-body {
  /* /padding-top: 2px; */
}

.header .navbar{
  background-color: var(--color-primary)!important
}

.nav-link{
  border-radius: 6px;
} 

.nav-link:hover {
  background-color: var(--color-primary);
}

.table thead {
  background-color: rgb(212, 212, 212);
}

.footer{
  background-color: var(--color-primary-dark);
  padding: 4px;
  padding-left: 1vw;
  padding-right: 1vw;
}
.footer a {
  color: white;
}

.footer a:hover {
  color: rgba(255, 255, 255, 0.774);
}

.float-right {
  color: white;
  border-radius: 6px;
  font-weight: normal;
  float: right;
  padding: 0px;
}


.react-datetime-picker__wrapper{
  border: thin solid lightgray!important;
  border-radius: 4px;
  padding: 4px;
  margin-top: 0px;
}

.react-datetime-picker__wrapper select{
  display: none;
}

.react-datetime-picker__wrapper button{
  display: none;
}